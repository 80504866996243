// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBcf9iCLEIVPDn4kBFkwRoBM5RCb_eUkOM",
    authDomain: "lewismedix.firebaseapp.com",
    databaseURL: "https://lewismedix-default-rtdb.firebaseio.com",
    projectId: "lewismedix",
    storageBucket: "lewismedix.appspot.com",
    messagingSenderId: "766795536954",
    appId: "1:766795536954:web:ed53103d761da71e61b303",
    measurementId: "G-8T573VBDRR"
  }  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
