import { Injectable } from '@angular/core';
import { DatabaseService } from '../database.service';
import { UsersService } from './users.service';
import { DocumentTranslationsService } from './abstract/document-translations.service';
import { Service, PageBlock, ServiceTranslation } from '../../models/collections/service.model';
import { now } from '../../helpers/functions.helper';
import { mergeMap, take } from 'rxjs/operators';
import { SettingsService } from '../settings.service';
import { Language } from '../../models/language.model';
import { Observable, of } from 'rxjs';
import { QueryFn } from '@angular/fire/firestore';

@Injectable()
export class ServicesService extends DocumentTranslationsService {

  constructor(
    protected db: DatabaseService,
    private settings: SettingsService,
    private users: UsersService
  ) {
    super(db, 'pageTranslations');
  }

  formatBlocks(blocks: PageBlock[]) {
    let formattedBlocks = {};
    blocks.forEach((block: PageBlock, index: number) => {
      let key = block.key || index;
      if (formattedBlocks[key]) {
        key += '-' + index;
      }
      formattedBlocks[key] = {
        name: block.name,
        type: block.type,
        content: block.content
      };
    });
    //console.log(blocks, formattedBlocks);
    return formattedBlocks;
  }

  add(data: Service, translationId?: string) {
    const service: Service = {
      title: data.title,
      lang: data.lang,
      slug: data.slug,
      blocks: data.blocks || {},
      createdAt: now(), // timestamp
      updatedAt: null,
      createdBy: this.db.currentUser.id,
      updatedBy: null
    };
    return new Promise((resolve, reject) => {
      this.db.addDocument('services', service).then((doc: any) => {
        this.addTranslation(data.lang, doc.id, translationId).then((translation: any) => {
          doc.set({ translationId: translationId || translation.id}, { merge: true }).then(() => {
            resolve();
          }).catch((error: Error) => {
            reject(error);
          });
        }).catch((error: Error) => {
          reject(error);
        });
      }).catch((error: Error) => {
        reject(error);
      });
    });
  }

  translate(data: Service) {
    return this.add(data, data.translationId);
  }

  get(id: string) {
    return this.db.getDocument('services', id).pipe(mergeMap(async (service: Service) => {
      const translations = service.translationId ? await this.getTranslations(service.translationId).pipe(take(1)).toPromise() : {};
      service.id = id;
      service.translations = translations;
      return service;
    }));
  }

  getTranslationLanguages(service: Service) {
    const serviceLanguages = Object.keys(service.translations);
    return this.settings.getActiveSupportedLanguages().filter((lang: Language) => serviceLanguages.indexOf(lang.key) === -1);
  }

  private pipeServices(pagesObservable: Observable<Service[]>) {
    return pagesObservable.pipe(mergeMap(async (services: Service[]) => {
      const activeSupportedLanguages = this.settings.getActiveSupportedLanguages().map((lang: Language) => lang.key);
      //pages.forEach((page: Page) => { // forEach loop doesn't seems to work well with async/await
      for (let service of services) {
        // console.log(page);
        service.translations = service.translationId ? await this.getTranslations(service.translationId).pipe(take(1)).toPromise() : {};
        // console.log(page.translations);
        const serviceLanguages = Object.keys(service.translations);
        service.author = service.createdBy ? this.users.getFullName(service.createdBy) : of(null);
        service.isTranslatable = !activeSupportedLanguages.every((lang: string) => serviceLanguages.includes(lang));
      }
      //});
      return services;
    }));
  }

  getAll() {
    return this.pipeServices(this.db.getCollection('services'));
  }

  getWhere(field: string, operator: firebase.firestore.WhereFilterOp, value: string, applyPipe: boolean = false) {
    return this.getWhereFn(ref => ref.where(field, operator, value), applyPipe);
  }

  getWhereFn(queryFn: QueryFn, applyPipe: boolean = false) {
    const servicesObservable = this.db.getCollection('services', queryFn);
    return applyPipe ? this.pipeServices(servicesObservable) : servicesObservable;
  }

  edit(id: string, data: Service) {
    const service: Service = {
      title: data.title,
      lang: data.lang,
      slug: data.slug,
      //blocks: data.blocks || {}, // blocks should be replaced instead of been merged
      updatedAt: now(),
      updatedBy: this.db.currentUser.id
    };
    return new Promise((resolve, reject) => {
      this.db.setDocument('services', id, service).then(() => {
        // replace blocks
        this.db.updateDocument('services', id, { blocks: data.blocks || {} }).then(() => {
          resolve();
        }).catch((error: Error) => {
          reject(error);
        });
      }).catch((error: Error) => {
        reject(error);
      });
    });
  }

  delete(id: string, data: { lang: string, translationId: string, translations: ServiceTranslation }) {
    return new Promise((resolve, reject) => {
      this.deleteTranslation(data.translationId, data.lang, data.translations).then(() => { // should be done before deleting document (pages observable will be synced before if not)
        this.db.deleteDocument('services', id).then(() => {
          resolve();
        }).catch((error: Error) => {
          reject(error);
        });
      }).catch((error: Error) => {
        reject(error);
      });
    });
  }

  isSlugDuplicated(slug: string, lang: string, id?: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getWhereFn(ref => ref.where('slug', '==', slug).where('lang', '==', lang)).pipe(take(1)).toPromise().then((services: Service[]) => {
        //console.log(pages, pages[0]['id']);
        resolve(services && services.length && (!id || (services[0]['id'] as any) !== id));
      }).catch((error: Error) => {
        reject(error);
      });
    });
  }

  countAll() {
    return this.db.getDocumentsCount('services');
  }

  countWhereFn(queryFn: QueryFn) {
    return this.db.getDocumentsCount('services', queryFn);
  }

  countWhere(field: string, operator: firebase.firestore.WhereFilterOp, value: string) {
    return this.countWhereFn(ref => ref.where(field, operator, value));
  }

}
